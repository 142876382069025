@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .action-icon {
    @apply cursor-pointer text-colorBrandBackground hover:text-colorBrandBackgroundHover;
  }

  .rotate {
    animation: rotate 2s linear infinite;
  }

  .section {
    @apply my-5 px-2 py-1;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 768px) {
  body {
    width: fit-content;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
